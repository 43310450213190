export function onClient() {
  if (typeof window === 'undefined') {
    return false;
  }
  if (window.onServer) {
    return false;
  }
  return typeof document !== 'undefined' && typeof navigator !== 'undefined';
}

export function onServer() {
  return !onClient();
}
