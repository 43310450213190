import Axios from 'axios';
import { onClient } from './ssr';

function setupCSRFToken() {
  if (onClient()) {
    const csrfToken = document.querySelector('meta[name=csrf-token]')?.content;
    if (csrfToken) {
      Axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;
    }
  }
}

setupCSRFToken();

export function getCSRFToken() {
  if (onClient()) {
    return document.querySelector('meta[name=csrf-token]')?.content;
  }
}

export function getCSRFParam() {
  if (onClient()) {
    return document.querySelector('meta[name=csrf-param]')?.content;
  }
}
